import axios from "axios";
import { HttpConsts, HttpRequest, HttpResponse } from "./";
import store from "../../../redux/configure-store";
import { ApiStatusAction } from "../../../redux/actions";
import { Utils } from "../shared";

export class HttpService {
  constructor(private baseUrl: string, public isUseAuth: boolean = true) {}

  public get authToken(): string | null {
    return sessionStorage.getItem(
      "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
    );
  }

  private getHeaders(isFormData: boolean = false): Record<string, string> {
    let token = "";
    if (this.authToken != null) {
      token = JSON.parse(this.authToken).access_token;
    }

    return {
      // Only set Content-Type for JSON requests, let browser set it for form data
      ...(isFormData ? {} : { "Content-Type": "application/json" }),
      Authorization: `Bearer ${token}`,
    };
  }

  get headers(): Object {
    let token = "";
    if (this.authToken != null) {
      token = JSON.parse(this.authToken).access_token;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return headers;
  }

  public get<ResponseType extends HttpResponse>(
    url: string
  ): Promise<ResponseType> {
    return new Promise<ResponseType>((resolve, reject) => {
      const trackingId = Utils.generateGuid();
      store.dispatch(
        ApiStatusAction.ApiCallStart(trackingId, `${this.baseUrl}${url}`, "GET")
      );

      axios
        .get(this.baseUrl + url, { headers: this.headers })
        .then((response: any) => {
          const httpResponse = response.data as ResponseType;

          if (httpResponse) {
            store.dispatch(
              ApiStatusAction.ApiCallSuccess(trackingId, response, httpResponse)
            );
          } else {
            store.dispatch(
              ApiStatusAction.ApiCallFail(trackingId, response, httpResponse)
            );
          }

          resolve(httpResponse);
        })

        .catch((err) => {
          store.dispatch(ApiStatusAction.ApiCallFail(trackingId, err, {}));
        });
    });
  }

  public async post<RequestType, ResponseType extends HttpResponse>(
    url: string,
    request: RequestType
  ): Promise<ResponseType> {
    const trackingId = Utils.generateGuid();
    store.dispatch(
      ApiStatusAction.ApiCallStart(trackingId, `${this.baseUrl}${url}`, "POST")
    );

    try {
      const isFormData = request instanceof URLSearchParams;
      const headers = this.getHeaders(isFormData);

      // If it's URLSearchParams, don't stringify the body
      const body = isFormData ? request : JSON.stringify(request);

      const response = await axios.post(this.baseUrl + url, body, {
        headers,
        // Set this when sending URLSearchParams to ensure correct Content-Type
        ...(isFormData ? { transformRequest: [] } : {}),
      });

      const httpResponse = response.data as ResponseType;

      if (httpResponse) {
        store.dispatch(
          ApiStatusAction.ApiCallSuccess(trackingId, response, httpResponse)
        );
        return httpResponse;
      } else {
        const errorResponse = {
          isSuccess: false,
          baseResponse: { status: HttpConsts.ErrorCode },
        } as ResponseType;
        store.dispatch(
          ApiStatusAction.ApiCallFail(trackingId, response, errorResponse)
        );
        return errorResponse;
      }
    } catch (err: any) {
      const errorResponse = {
        isSuccess: false,
        baseResponse: {
          status: HttpConsts.ErrorCode,
          httpStatusCode: err?.response?.status ?? -1,
        },
      } as ResponseType;

      store.dispatch(
        ApiStatusAction.ApiCallFail(trackingId, err, errorResponse)
      );
      return errorResponse;
    }
  }
}
