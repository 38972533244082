import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IRenewal } from "../../App/one-platform/vo/renewal/renewal/renewal.interface";

const renewalReducer = (
  state: IRenewal = initialState.loginDetails,
  action: any
): IRenewal => {
  switch (action.type) {
    case ActionTypes.GET_RENEWAL_LOGIN_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default renewalReducer;
