import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { RenewalService } from "../../App/one-platform/vo/renewal/renewal/renewal.service";
import { HttpResponse } from "../../App/one-platform/core";

// Define the login response interface
export interface LoginResponse extends HttpResponse {
  access_token?: string;
  token_type?: string;
  expires_in?: number;
}

// Define action interface that works with LoginResponse
export interface IRenewalAction extends IAction<string, LoginResponse> {
  type: string;
  payLoad: LoginResponse;
}

export class RenewalAction {
  static PostRenewalSuccess(loginDetails: LoginResponse): IRenewalAction {
    return {
      type: ActionTypes.GET_RENEWAL_LOGIN_DETAILS,
      payLoad: loginDetails,
    };
  }

  static PostRenewal(data: {
    username: string;
    password: string;
    grant_type: string;
    client_id: string;
    culture_code: string;
    user_type: string;
  }): Function {
    return async (dispatch: any): Promise<void> => {
      try {
        const loginDetails = await new RenewalService().GetLoginDetails(data);
        dispatch(RenewalAction.PostRenewalSuccess(loginDetails));
      } catch (error) {
        throw error;
      }
    };
  }
}
