import { combineReducers } from "redux";
import { IAction } from "../actions";
import apiCallInProgress from "./api-status.reducer";
import vehicleDeatilsReducer from "./vehicle-details.reducer";

import { initialState, IState } from "./index";
import {
  productDeatilsReducer,
  productDeatilsByIdReducer,
  addOnProductDeatilsReducer,
  mbiProductPriceReducer,
  updateSelectedProductsReducer,
  termDocReducer,
} from "./product-details.reducer";
import personalDeatilsReducer from "./personal-details.reducer";
import {
  brandGroupDeatailsReducer,
  brandsDeatailsReducer,
  dealerDeatilsReducer,
} from "./dealer-details.reducer";
import {
  insuranceDeatilsReducer,
  pdfDocReducer,
} from "./insurance-details.reducer";
import warrantyOverviewDeatilsReducer from "./warranty-overview.reducer";
import priceListReducer from "./price-list.reducer";
import {
  prodctNameListReducer,
  dealerProductDeatilsReducer,
} from "./product-sell.reducer";
import offerLetterReducer from "./offer-letter.reducer";
import renewalReducer from "./renewal.reducer";

const appReducer = combineReducers({
  apiCallInProgress,
  vehicleData: vehicleDeatilsReducer,
  productData: productDeatilsReducer,
  periodData: productDeatilsByIdReducer,
  addonProductData: addOnProductDeatilsReducer,
  mbiProductPrice: mbiProductPriceReducer,
  personalData: personalDeatilsReducer,
  dealerData: dealerDeatilsReducer,
  brandGroupData: brandGroupDeatailsReducer,
  brandsData: brandsDeatailsReducer,
  selectedProducts: updateSelectedProductsReducer,
  insuranceData: insuranceDeatilsReducer,
  pdfDocUrl: pdfDocReducer,
  warrantyOverviewData: warrantyOverviewDeatilsReducer,
  priceListData: priceListReducer,
  productNameListData: prodctNameListReducer,
  dealerProductData: dealerProductDeatilsReducer,
  offerLetterLoginDetails: offerLetterReducer,
  loginDetails: renewalReducer,
  termDocUrl: termDocReducer,
});

export const rootReducer = (
  state: IState = initialState,
  action: IAction<string, IState>
) => {
  switch (action.type) {
    default:
      return appReducer(state, action);
  }
};
