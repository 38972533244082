import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../../redux/reducers";
import { IRenewal } from "./renewal.interface";
import { Utils } from "../../../shared";
import { RenewalAction } from "../../../../../redux/actions/renewal.action";

type T = {
  loginDetails?: IRenewal;
  renewal?: (data: any) => Promise<IRenewal>;
};

const RenewalComponent = ({ loginDetails, renewal }: T): JSX.Element => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const username = queryParams.get("1");
    const password = queryParams.get("2");

    if (username != null && password != null) {
      let lang = queryParams.get("lang");

      const data = {
        username: atob(username).trim(),
        password: atob(password),
        grant_type: "password",
        client_id: "AutoconceptWeb",
        culture_code: lang,
        user_type: 3,
      };

      renewal!(data);
    }
  }, []);

  useEffect(() => {
    if (loginDetails) {
      const queryParams = new URLSearchParams(window.location.search);
      let lang = queryParams.get("lang");
      let Vplang = "sv";
      const data = loginDetails;
      if (data != undefined && data.body != undefined) {
        switch (lang) {
          case "sv-SE":
            data.body.cultureCode = "sv-se";
            lang = "sv-SE";
            Vplang = "sv";
            break;
          case "nn-NO":
            data.body.cultureCode = "nn-no";
            lang = "nn-NO";
            Vplang = "no";
            break;
          case "da-DK":
            data.body.cultureCode = "da-dk";
            lang = "da-DK";
            Vplang = "da";
            break;
          case "fi-FI":
            data.body.cultureCode = "fi-fi";
            lang = "fi-FI";
            Vplang = "fi";
            break;
          default:
            data.body.cultureCode = "sv-se";
            lang = "sv-SE";
            Vplang = "sv";
            break;
        }
        sessionStorage.removeItem(
          "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
        );
        sessionStorage.setItem(
          "1986authdata19901985dataprocess_autoconcept_vo_access_token_info",
          JSON.stringify(data.body)
        );
        sessionStorage.setItem("isLogin", "1");
        sessionStorage.setItem("VpLang", Vplang);
        sessionStorage.setItem("platform", "vop");
        sessionStorage.setItem(
          "originUrl",
          btoa(`/vo-login?lang=${Utils.getCurrentLang()}`)
        );
        window.location.href = "/mypages/home?lang=" + lang + "&platform=vop";
      }
    }
  }, [loginDetails]);

  return <div></div>;
};

const mapStateToProps: any = (state: IState): any => {
  return { loginDetails: state.loginDetails };
};

const mapDispatchToProps: any = {
  renewal: RenewalAction.PostRenewal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewalComponent);
