import { HttpRequest, HttpResponse, HttpService } from "../../../core";
import axios from "axios";

export interface LoginResponse extends HttpResponse {
  access_token?: string;
  token_type?: string;
  expires_in?: number;
}

export class RenewalService {
  private readonly baseUrl: string = process.env.REACT_APP_BE_URL!;
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }

  async GetLoginDetails(data: {
    username: string;
    password: string;
    grant_type: string;
    client_id: string;
    culture_code: string;
    user_type: string;
  }): Promise<LoginResponse> {
    const formData = new URLSearchParams();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("grant_type", data.grant_type);
    formData.append("client_id", data.client_id);
    formData.append("culture_code", data.culture_code);
    formData.append("user_type", data.user_type);

    // Direct axios call to handle form data properly
    const response = await axios.post(`${this.baseUrl}/Token`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return response.data;
  }
}
