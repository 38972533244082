import React from "react";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import VORegistration from "./module-entry/vo/vo-new-vehicle";
import VOProductList from "./module-entry/vo/vo-product";
import InsuranceListComponent from "./module-entry/vo/vo-insurance";
import VOInformation from "./module-entry/vo/vo-information";
import VOProdReview from "./module-entry/vo/vo-review";
import VOCheckout from "./module-entry/vo/vo-checkout";
import VOPaymentAccept from "./module-entry/vo/vo-payment-accepted";
import VOPaymentCancel from "./module-entry/vo/vo-payment-cancel";
import InternationalClaim from "./module-entry/vo/international-claim";
import ClaimThankYou from "./module-entry/vo/claim-thank-you";
import WarrantyRegistrationPage from "./App/one-platform/dealer/warranty-registartion/warranty-registration.page";
import WarrantyRegistrationSuccessPage from "./App/one-platform/dealer/warranty-registartion/warranty-registration-success.page";
import PageNotFound from "./module-entry/vo/page-not-found";
import Header from "./shared/header.component";
import Footer from "./shared/footer.component";
import Title from "./shared/title.component";
import Guards from "./services/vo/guard-service";
import { GuardComponent } from "./utils/enum/guard-component-enum";
import { useTranslation } from "react-i18next";
import VOLoginApp from "./module-entry/vo/vo-login";
import { VOHomeComponent } from "./App/one-platform/vo";
import { useVisibility } from "./App/one-platform/shared";
import VOWarrentyApp from "./module-entry/vo/vo-warranty";
import WarrantyOverviewPage from "./App/one-platform/dealer/warranty-overview/warranty-overview.page";
import PriceListPage from "./App/one-platform/dealer/price-list/price-list.page";
import ProductsSellPage from "./App/one-platform/dealer/products-sell/products-sell.page";
import OfferLetterRenewalPage from "./App/one-platform/vo/renewal/offer-letter-renewal/offer-letter-renewal.page";
import { DHomeComponent } from "./App/one-platform/vo/d-home.component";
import VOProfileApp from "./module-entry/vo/vo-profile";
import RenewalPage from "./App/one-platform/vo/renewal/renewal/renewal.page";

const AppComponent = () => {
  const { t } = useTranslation();
  const { isVisible } = useVisibility();

  return (
    <>
      {isVisible && <Route component={Header} />}
      <div className="body-container-wrapper">
        <div className="body-container container-fluid">
          {isVisible && <Route component={Title} />}
          <div className="row-fluid-wrapper row-depth-1 row-number-4">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell content-section"
                data-widget-type="cell"
                data-x="0"
                data-w="12"
              >
                <div className="row-fluid-wrapper row-depth-1 row-number-5">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-cell centered md-stack"
                      data-widget-type="cell"
                      data-x="0"
                      data-w="12"
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-6">
                        <div className="row-fluid">
                          <div
                            className="span12 widget-span widget-type-custom_widget"
                            data-widget-type="custom_widget"
                            data-x="0"
                            data-w="12"
                          >
                            <div
                              id="hs_cos_wrapper_module_1615269673754290"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div
                                className="information-module"
                                style={{ minHeight: 500 }}
                              >
                                <GuardProvider
                                  guards={[
                                    Guards.RequiredLogin,
                                    Guards.ComponentSpecificGuard,
                                  ]}
                                >
                                  <Switch>
                                    <GuardedRoute
                                      path="/vo-profile"
                                      render={VOProfileApp}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PrivateVOPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/insurance-overview"
                                      render={VOWarrentyApp}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PrivateVOPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/vo-login"
                                      render={VOLoginApp}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/d-login"
                                      render={VOLoginApp}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/mypages/home"
                                      component={VOHomeComponent}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PrivateVOPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/dealer/home"
                                      component={DHomeComponent}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PrivateVOPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/new-vehicle"
                                      render={VORegistration}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/product"
                                      render={VOProductList}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/insurance"
                                      render={InsuranceListComponent}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/information"
                                      render={VOInformation}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/review"
                                      render={VOProdReview}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/checkout"
                                      render={VOCheckout}
                                      meta={{ auth: false }}
                                    />
                                    <GuardedRoute
                                      path="/payment-accept"
                                      render={VOPaymentAccept}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PaymentAccept,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/payment-cancel"
                                      render={VOPaymentCancel}
                                      meta={{
                                        auth: true,
                                        component: GuardComponent.PaymentCancel,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/internationalclaim"
                                      render={InternationalClaim}
                                      meta={{
                                        auth: false,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/claim-success"
                                      render={ClaimThankYou}
                                      meta={{
                                        auth: false,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/claim"
                                      render={InternationalClaim}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/warranty-registration"
                                      render={WarrantyRegistrationPage}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/warranty-registration-success"
                                      render={WarrantyRegistrationSuccessPage}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/warranty-overview"
                                      render={WarrantyOverviewPage}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/price-list"
                                      render={PriceListPage}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/product-to-sell"
                                      render={ProductsSellPage}
                                      meta={{
                                        auth: true,
                                        component:
                                          GuardComponent.PrivateDealerPage,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/offer-letter-renewal"
                                      render={OfferLetterRenewalPage}
                                      meta={{
                                        auth: false,
                                      }}
                                    />
                                    <GuardedRoute
                                      path="/renewal"
                                      render={RenewalPage}
                                      meta={{
                                        auth: false,
                                      }}
                                    />
                                    <Redirect
                                      from="/"
                                      exact
                                      to="/vo-login?platform=vop&lang=sv-SE"
                                    />
                                    <Route component={PageNotFound} />
                                  </Switch>
                                </GuardProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row-fluid-wrapper row-depth-1 row-number-7">
                        <div className="row-fluid">
                          <div
                            className="span12 widget-span widget-type-custom_widget tc"
                            data-widget-type="custom_widget"
                            data-x="0"
                            data-w="12"
                          >
                            <div
                              id="hs_cos_wrapper_module_1618406587307437"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <span
                                id="hs_cos_wrapper_module_1618406587307437_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p className="bottom-text">
                                  {t("cus-sup")}
                                  <a
                                    className="m-l-cus-sup"
                                    href={`tel:+${t("cus-sup_number")}`}
                                  >
                                    {t("cus-sup_number")}
                                  </a>
                                  {/* eller maila till
                                <a
                                  href="mailto:info@autoconcept.se"
                                  rel="noopener"
                                >
                                  <span> </span> info@autoconcept.se
                                </a> */}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisible && <Footer />}
    </>
  );
};
AppComponent.propTypes = {
  authUser: PropTypes.any.isRequired,
  loadAssetList: PropTypes.any.isRequired,
  assetList: PropTypes.any.isRequired,
};
export default AppComponent;
